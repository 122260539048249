export const noteList = [
  {
    title: `[Bleach Your Mind] GLEN CHECK ‘I Feel Like Ridin Slow’ M/V`,
    subItems: [
      {
        url: `https://www.youtube.com/embed/bdKnGhK1ySI`,
        ratio: `16:9`,
      },
    ],
    content: [
      `봄이 오면 꽃이 피고, 여름이면 꽃이 져서 가을이면 열매를 맺는다.`,
      `이처럼 ‘대자연의 법칙'에 의해 이 세상은 쉬지 않고 움직인다.`,
      `나에겐 이번 영상이 스케치북이 되었고 꽃향이 가득한 그림책을 만들었다.`,
    ],
    summary: [],
    needs: [],
    solution: [],
  },
  {
    title: `[Bleach Your Mind] GLEN CHECK ‘BLUSH (Feat. Sokodomo)’ M/V`,
    subItems: [
      {
        url: `https://www.youtube.com/embed/uCGpI6Vt4c4`,
        ratio: `16:9`,
      },
    ],
    content: [
      `한 남자가 한 여자에게 사랑에 빠졌을 때 그녀를 대하는 태도는 자신을 사랑하듯 그 사람을 인정하고 이해하며 꼭 안아주고 싶다는 것 같다.`,
      `가장 무서운 것은 사랑을 시작함과 동시에 자신을 잃을 정도로 그녀를 사랑하여 자신도 모르는 사이 여유를 잃어버리고 절벽끝에서 허우적대는 자신에게 화가 차는 것을 그녀에게 떠넘기는 상황이 생기면서 돌아오는 슬픔도 곁에서 같이 시작된다는 것이다.`,
      `모든 사람들은 이기적이다. 하지만 처음 가졌던 마음가짐을 잊지 않는다면 그 끝 또한 아름다울 것이다.`,
      `글렌체크에 BLUSH를 듣다보면 앞서 말했던 한 없이 철없는 시절의 내가 생각난다. 열정과 활기가 넘쳐 모든 것들이 빛나보이고 할 수 있다는 믿음이 생겼던 나날들. (물론 지금 또한 다른 방식으로 그렇다.)`,
      `그 나날들을 아름답게 표현할 공간을 물색하다가 힘찬 응원과 함성 소리가 끊이지 않았던 ‘경기장(농구장)’이 생각났다.`,
      `글렌체크의 힘찬 응원가를 듣고 싶다면 지금 바로 ‘BLUSH’를 시청해보길 바란다.`,
      `*상대의 사랑을 확인하고 싶거나 성공적인 연애 생활을 유지하기 위해서 항상 상대에게 반해 있어야 하는 것은 아니다.`,
      `진정한 사랑은 시간과 노력이 필요하다. 사랑이 싹트는 데는 많은 주의가 필요하다. 사랑한다와 사랑하지 않는다라는 극단적인 선택만 남겨두는 사람은 안타깝게도 사랑의 중간 과정을 간과해버린다.`,
      `사랑을 원만하게 유지하고 사소한 문제를 지나치게 따지지 말자. 상대는 그저 당신의 사랑을 확인하고 싶을 뿐이다.`,
    ],
    summary: [],
    needs: [],
    solution: [],
  },
  {
    title: `[Bleach Your Mind] GLEN CHECK ‘SINS’ M/V`,
    subItems: [
      {
        url: `https://www.youtube.com/embed/6pFG-VYxr9Q`,
        ratio: `16:9`,
      },
    ],
    content: [
      `갑작스러운 EMA 크리에이터 디렉터 ‘제프리’님의 연락을 받고 미팅을 했다.`,
      `글렌체크의 공백기간을 걸쳐 만들어진 앨범의 타이틀 곡에 뮤직비디오 제작을 요청했다.`,
      `타이틀 곡에 뮤직비디오라 하고 싶은 것들이 많았지만 평소에 멤버 ‘김준원’과 대화를 할때마다 립싱크 퍼포먼스를 제대로 보여주는 영상을 만들면 정말 멋진 그림이 나올 것 같다는 이야기를 많이 했기에 댄서 ‘아이반' 님과 함께하는 퍼포먼스 영상을 제대로 만들어 보기로 했다.`,
      `‘죄악'을 뜻하는 RED LIGHT을 통해 아이반님의 퍼포먼스를 보여주었고 BLUE LIGHT를 통해 보여지는 ‘GLEN CHECK’가 결국 자신의 죄를 씻어내고 극복하는 모습을 보여주고 싶었다.`,
      ` `,
      `진정한 인간이란, 많은 지식도 능력도 자신감도 성공에 의해서도 아닌, 자신의 나약함을 인정하고 받아들이며 눈물로 그것을 토로한 순간 가능해지는 것은 아닐까.`,
      `그것이 하나의 시작점이 되는 것은 아닌지, 생각해보게 되는 순간이었다.`,
    ],
    summary: [],
    needs: [],
    solution: [],
  },
  {
    title: `[Bleach Your Mind] GLEN CHECK ‘4EVER’ M/V`,
    subItems: [
      {
        url: `https://www.youtube.com/embed/GeTz0DkHZvo`,
        ratio: `16:9`,
      },
    ],
    content: [
      `1.자신만의 공간에서 자신만의 삶을 누군가에게 보여준다는건 굉장히 큰 용기가 필요하다.`,
      `GLEN CHECK에 프론트맨 ‘김준원’은 사람적인 면모를 사람들에게 비춰지길 꺼려했던 아티스트이다.`,
      `이번 작업을 통해서 그 틀을 깨고 세상밖으로 나오기전 그가 평소에 자신의 공간에서 작업하던 모습들을 담아내고 싶었다.`,
      ``,
      `2.혼자만의 시간을 소중하게 여겼던 강박관념을 가진 소년은 밖을 동경하지만 한편으로는 밖에서 일어나는 돌발적인 상황들을 두려워하기도 한다.`,
      `결국 그는 한발자국도 밖을 향해 내딛지 못하지만 반짝이는 빌딩들을 바라보며 자신만의 세계관 속에 자신만의 방식대로 그려낸 세상을 담아낸다.`,
      `모든 것들이 영원할 수 있도록 ‘4EVER’.`,
    ],
    summary: [],
    needs: [],
    solution: [],
  },
  {
    title: `DVWN 'it's not your fault' Visualizer.`,
    subItems: [
      {
        url: `https://www.youtube.com/embed/vHAOZlRsmz8`,
        ratio: `16:9`,
      },
    ],
    content: [
      `“누군가를 완전히 이해할려고 하는 것도 그 사람에게 해를 입힐 수 있다.”`,
      `라는 이야기를 들었다. 사람들은 각자가 겪어온 세상과 주변 인물들이 다르다.`,
      `이해를 하고 공감을 했다고 한들 그 사람이 느끼는 감정들을 온전히 느낄 수 없다.`,
      ``,
      `우리는 의미를 가진 존재다. 하지만 그 의미를 너무 오랫동안 가볍게 생각해왔다.`,
      `내가 더는 나약하지 않고 내 분야를 통해 이 세상을 보다 의미 있게 바꿀 수 있다는 나 자신을 `,
      `초월하는 신념과 믿음 그리고 내가 걸어가는 이 발걸음들이 모두 의미가 있다는 것을 인지 할때, `,
      `우리는 우리를 향한 적대적인 많은 환경 신호 앞에서 단단해 질 수 있고 `,
      `의미 있는 변화를 만들어 낼 수 있다.`,
      `그리고 그 변화는 풍요로운 어둠 속에서 더 황홀한 빛으로 만들어진다.`,
      ``,
      `모든 일들은 시작과 끝이 있다.`,
      `자책하지말고 더 즐기며 살 수 있는 세상이 되었으면 좋겠다.`,
    ],
    summary: [],
    needs: [],
    solution: [],
  },
  {
    title: `Dvwn '자유비행' 마음의 풍요를 찾아서. `,
    subItems: [
      {
        url: `https://player.vimeo.com/video/512347574`,
        ratio: `16:9`,
      },
    ],
    content: [
      `디지털화의 발전이 거듭되어가고 점점 더 심해지는 개인주의 속에서 격동의 시기를 겪고 있는 우리들.`,
      `우리는 점점 더 성공과 실패란 궤도 안에서 자신을 갈구하고 남들을 시기, 질투하며 자신의 사리사욕을 채운다.`,
      `자극적인 미디어를 접하며 점점 더무뎌져 가는 감정 그리고 그 안에서 자신이 원하는 길을 잃어버리며 좌절해본 적이 있을 것이다.`,
      `결국 행복한 인생이란 긴 마라톤에서 하루하루를 감사하며 살아가며 풍요로운 마음을 찾아가는 과정이 아닐까?`,
      `KOZ의 아티스트 Dvwn의 ‘자유비행’은 우리에게 빛을 보여주는 노래였고 우리의 새로운 빛을 통해 화창한 마음을 찾아가는 시간을 가졌다.`,
    ],
    summary: [],
    needs: [],
    solution: [],
  },
  {
    title: `뮤지션 Dvwn '서쪽 끝자리에 서서’.`,
    subItems: [
      {
        url: `https://www.youtube.com/embed/G-uMzkrpELE`,
        ratio: `16:9`,
      },
    ],
    content: [
      `1년에 밝은 햇살을 맞아본 순간이 몇 번이나 있을까?`,
      `예술가들도 회사에서 부장님에게 잔소리를 들으며 아등바등 사는 회사원도 삶과 죽음의 문턱에서 
            기억의 조각들을 찾는 사람도 누군가와 함께 같은 시간에 같은 해를 보기란 너무나 소중한 일이다.`,
      `서쪽으로 지는 해가 우리 머리에 중심에 떠있을 때 즈음 그날 하루에 많은 감사함을 느꼈다.`,
    ],
    summary: [],
    needs: [],
    solution: [],
  },
  {
    title: `Donny Hathaway ‘This Christmas’ A Cover Live by Dvwn & SOLE.`,
    subItems: [
      {
        url: `https://www.youtube.com/embed/S1h2fvrvahQ`,
        ratio: `16:9`,
      },
    ],
    content: [
      `무한 경쟁 시대와 코로나라는 병이 세상을 위협하여 감성이 없어진지 오래되어가는 것 같다.`,
      `유년 시절 기대감에 부풀었던 크리스마스와는 사뭇 다른 2020년에 크리스마스를 보낼 것만 같았다.`,
      `아티스트 다운과 솔이 전해주는 목소리에 교집합은 다시금 크리스마스를 따뜻하게 감싸주었다.`,
    ],
    summary: [],
    needs: [],
    solution: [],
  },
  {
    title: `Teayeon ‘Sorrow’ Studio Live.`,
    subItems: [
      {
        url: `https://www.youtube.com/embed/rZO7Fe-6pxo`,
        ratio: `16:9`,
      },
    ],
    content: [
      `어떤 가수가 가장 빛날까?`,
      `군대 시절 작은 TV안에 ‘소녀시대’부터 지금까지 봐온 매력적인 가수 ‘태연’의 라이브 컨텐츠 제작 문의가 들어왔다.`,
      `실제 녹음현장에서도 그녀의 목소리는 빛났으며 그 빛을 통해 보여지는 태연의 모습을 현장 포착하여 
            영상을 보는 관객들로 하여금 가수 태연의 목소리를 만끽할 수 있게 해주고 싶었다.`,
    ],
    summary: [],
    needs: [],
    solution: [],
  },
  {
    title: `6개월간의 대장정 DOCUMENTARY 'WHO IS JUNEONE?' 제작기.`,
    subItems: [
      {
        url: `https://player.vimeo.com/video/580135709`,
        ratio: `16:9`,
      },
    ],
    content: [
      `밴드 *글렌체크(Glen Check)의 리더 '김준원'이라는 아티스트는 제가 만나본 예술가 중 가장 민감하고도 강박이 있는 사람이었다.`,
      `하지만 사람으로는 그 누구보다 헌신적이고 남을 위할 줄 아는 성격의 소유자였으며 우리는 
            공통된 분모가 무엇인지도 모른 체 서로에게 이끌려 가까워졌다.`,
      `처음엔 글렌체크(Glen Check)라는 밴드도 몰랐었지만 그의 눈에 비치는 
            아우라에 매료되었고 그의 정신적인 상태와 피 같은 노력들을 어깨너머로 느낄 수 있었다.`,
      `여전히 그는 노력 중이고 한계에 도달하여도 극복하며 자신에게 당근 대신에 채찍을 휘두르며 
            앞으로 나아가는 모습이 저를 움직이는 원동력이 되었으며 이 다큐멘터리는 그의 팬이기 이전에 친구로서 꼭 해야 할 일을 했을 뿐이다.`,
      `*글렌체크(Glen Check)는 대한민국의 신스팝, 일렉트로닉 밴드이다. 
            2011년 Disco Elevator 라는 EP 앨범으로 데뷔했다. 
            2013년 제 10회와 2014년 제 11회를 연달아 한국대중음악상 최우수 댄스일렉트로닉음반상을 2년 연속 수상했다.`,
    ],
    summary: [],
    needs: [],
    solution: [],
  },
  {
    title: `뮤지션 진보(JINBO·본명 한주현) 과 함께한 ‘비싸' 뮤직비디오 제작기.`,
    subItems: [
      {
        url: `https://player.vimeo.com/video/394382257`,
        ratio: `16:9`,
      },
    ],
    content: [
      `뮤지션 진보(JINBO·본명 한주현) 많은 R&B·힙합 팬들에겐 '믿고 듣는' 이름으로 자리 잡은 지 오래다. 
            메이저와 언더그라운드를 오가며 견고한 존재감을 쌓아 올린 그가 다소 긴 공백 끝에 최근 자기 이름을 내걸고 
            3집 정규 앨범 ‘Don’t think too much’를 선보였다.`,
      `"프로듀서, 아티스트 같은 명함 바깥에 있는 내 진짜 모습을 보여주고 싶었다. 
            모나고 어둡고 또한 꽤나 바르고 빛나는 내 모습"이라는 앨범 소개 글 표현처럼, 
            복잡하게 교차하는 자기 안 양면성을 담았다는 진보의 어렸을 적 캠코더 영상들을 
            조합하여 ‘비싸’라는 트랙에 뮤직비디오를 제작하였다.`,
    ],
    summary: [],
    needs: [],
    solution: [],
  },
  {
    title: `뮤지션 진보(JINBO·본명 한주현) 과 함께한 전시회 필름제작.`,
    subItems: [
      {
        url: `https://player.vimeo.com/video/418429463`,
        ratio: `16:9`,
      },
    ],
    content: [
      `진보는 자신에게 할말이 많아 보였다.`,
      `그는 자신을 갈구하고 자신을 미워하기도 하며 자신이 가진 재능보다는 
            열정과 노력이 보상 해준다는 걸 누구보다 잘 알고 있는 아티스트였다.`,
      `내가 오래전에 영감을 받고 나의 멘토였던 학창 시절 선배님과 함께 
            있는 것 같은 편안함 속에서 길거리와 강변북로를 오가며 인터뷰를 했고 
            그의 생각을 정리한 내레이션이 곁들여져 더 큰 영감과 에너지를 얻었던 보람찬 작업이었다.`,
    ],
    summary: [],
    needs: [],
    solution: [],
  },
];
