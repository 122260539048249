export const clientCompanyList = [
  "PLEDIS ENT",
  "ADIDAS",
  "PUMA",
  "REEBOK",
  "MUSINSA",
  "GQ KOREA",
  "W KOREA",
  "SM ENTERTAINMENT",
  "KOZ ENTERTAINMENT",
  "WOONGJIN THINKBIG",
  "EYESMAG",
  "SOAPSEOUL",
  "UNDER ARMOUR",
  "YVES SAINT LAURANT",
  "GOLDEN GOOSE",
  "MARK GONZALES",
  "MKIT RAIN",
  "ASPARAGUS",
  "ONEHUNNNIT",
  "LABELESS",
  "NEW WAVE RECORDS",
  "NONDEFINE",
  "LUC BELAIRE",
  "THE QOOS",
  "STONESHIP ENTERTAINMENT",
];

export const clientArtistList = [
  "VERNON",
  "GLENCHECK",
  "OWEN OVADOZ",
  "KEITH APE",
  "B-FREE",
  "BLOO (블루)",
  "JUNE ONE (GLENCHECK)",
  "TAEYEON (SM ENTERTAINMENT)",
  "MAX CHANGMIN (SM ENTERTAINMENT)",
  "DVWN (KOZ ENTERTAINMENT)",
  "JEREMY QUE$T",
  "DHL",
  "DJ CONAN",
  "FS GREEN",
  "JINBO THE SUPERFREAK",
  "MBA EK",
  "BIG ONE",
  "GENNEO",
];
